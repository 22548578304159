<template>
  <div>
    <van-nav-bar title="拓客申请" left-arrow :fixed="true" :z-index="999" @click-left="back" />

    <div style="padding-top:46px;">
      <div style="position: relative;  height: 45px;">
        <van-search v-model.trim="keyword" placeholder="请输入相关关键词" @focus="put" />
        <div v-show="keyword" class="shikuan" v-if="show">
          <ul>
            <li
              v-for="item in cityList"
              :key="item.id"
              @click="handleCityClick(item)"
              class="linam"
            >{{item.garageName}}</li>
          </ul>
        </div>
      </div>
      <van-row v-if="list">
        <van-col span="20" class="ls">{{list.garageName}}</van-col>
        <van-col span="4" class="rs" @click="detparts">删除</van-col>
      </van-row>
    </div>
    <div class="sumit">
      <van-button type="danger" size="normal" @click="back">取消</van-button>
      <van-button type="primary" size="normal" @click="submitForm">提交申请</van-button>
    </div>
  </div>
</template>

<script>
import userwei from "../../api/user";
const delay = (function() {
  let timer = 0;
  return function(callback, ms) {
    clearTimeout(timer);
    timer = setTimeout(callback.ms);
  };
})();
export default {
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      show: false,
      list: null,
      keyword: "",
      cityList: [],
      timer: null,
      jsonData: []
    };
  },
  watch: {
    keyword() {
      //函数节流
      if (this.timer) {
        clearTimeout(this.timer);
      }
      //删除文字  清零
      if (!this.keyword) {
        this.cityList = [];
        return;
      }
      this.timer = setTimeout(() => {
        const result = [];
        // console.log(this.jsonData);
        this.jsonData.forEach(val => {
          if (val.garageName.indexOf(this.keyword) > -1) {
            result.push(val);
          }
        });
        this.cityList = result;
        // console.log(this.cityList);
      }, 100);
    }
  },
  methods: {
    onLoad() {
      userwei
        .queryGarageInfo()
        .then(e => {
          // loading.clear();
          this.jsonData = e.data;
          // console.log(this.jsonData)
        })
        .catch(() => loading.clear());
    },
    handleCityClick(e) {
      console.log(e);
      this.keyword = e.garageName;
      this.list = e;
      this.show = false;
    },
    detparts() {
      this.keyword = "";
      this.list = null;
    },
    put() {
      this.show = true;
    },
    submitForm() {
      //  location.replace('/tuoke') 
      if (!this.list) {
        return this.$toast("请先选择修理厂");
      }
      let data = {
        customerId: this.userInfo.id,
        garageName: this.list.garageName,
        gid: this.list.id,
        name: this.userInfo.realName,
        phone: this.userInfo.phone
      };
      userwei
        .apply(data)
        .then(res => {
          console.log(res)
          if (res.code === 200) {
            this.$toast.success("提交成功");
            // setTimeout(() => {
            //   location.replace('/tuoke')
            // }, 1000);
          } else {
            this.$toast(res.message);
          }
        })
        .catch(() => loading.clear());
    },

   back() {
    //  console.log('45646')
     this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="less" scoped>
.views {
  /deep/ div:nth-of-type(2) span {
    color: #1989fa;
  }
}
.sumit {
  width: 100%;
  padding: 10px;
  position: absolute;
  bottom: 0;
}
.van-button {
  width: 50%;
}
.van-row {
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #ebedf0;
}
.ls {
  padding-left: 20px;
}
.rs {
  text-align: right;
  padding-right: 20px;
  color: #ee0a24;
}
.van-search {
  padding: 0;
  
}
.van-cell {
width: 90%;
  margin: auto;
}
.shikuan {
  border: 1px solid #ebedf0;
  margin: 0px 5%;
  border-top: none;
  z-index: 99;
  position: absolute;
  top: 0;
  margin-top: 35px;
  width: 90%;
  left: 0;
  background: #fff;
}
.van-search {
  width: 90%;
  margin: auto;
}
.linam {
  padding-left: 10px;
  height: 30px;
  font-size: 13px;
  line-height: 30px;
}
</style>
